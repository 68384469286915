import React, {SyntheticEvent, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';

import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import Panel from 'web/components/panel';
import SubmitButton from 'web/components/submit_button';
import {PageType} from 'web/helpers/redux_client';
import Alert from 'web/components/alert';

import AccountLayout from '../components/layout';
import {StoreData} from './controller';
import {getCustomerPortalUrl} from './helpers/api_client';

const MembershipPage: PageType<StoreData> = () => {
  const initialState = useSelector((s: StoreData) => {
    return {...s};
  });
  const [state, setState] = useState(initialState);
  const hasBeenMember = state.membership?.startAt != null || state.membership?.startDate != null;
  const isActiveMember = state.membership?.isMember ?? false;

  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    try {
      const stripeUrl = await getCustomerPortalUrl();
      window.location.replace(stripeUrl);
    } catch (_err) {
      const errorState = {
        ...state,
        error: {
          heading: `Error`,
          message: 'Sorry, there was a problem connecting to the Membership Management Portal.',
        },
      };
      setState(errorState);
    }
  };

  const handleRedirect = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    document.location.href = '/join-membership';
  };

  return (
    <MarketLayout disableUpcomingOrdersBanner disableBasketDropdown>
      <Helmet>
        <title>Membership | Good Eggs</title>
      </Helmet>

      <div className="membership-page">
        <AccountLayout slug="membership">
          <Panel header="Membership">
            <div className="membership-page__section">
              {hasBeenMember ? (
                <div>
                  <p>
                    Your membership is currently{' '}
                    <strong>{isActiveMember ? 'active' : 'inactive'}</strong>.
                  </p>
                  <SubmitButton className="membership-page__button button" onClick={handleSubmit}>
                    Manage Membership
                  </SubmitButton>
                </div>
              ) : (
                <div data-testid="manage-membership__null">
                  <p>Become a Good Eggs Member!</p>
                  <SubmitButton className="membership-page__button button" onClick={handleRedirect}>
                    Sign Up
                  </SubmitButton>
                </div>
              )}
              {state.error != null ? (
                <div className="membership-page__error" data-testid="manage-membership__error">
                  <Alert type="error" heading={state.error.heading}>
                    {state.error.message}
                  </Alert>
                </div>
              ) : null}
            </div>
          </Panel>
        </AccountLayout>
      </div>
    </MarketLayout>
  );
};

MembershipPage.pageName = 'Membership';
MembershipPage.reducer = (state, action) => {
  if (!state) throw new Error('State should always be preloaded here');
  return marketLayoutReducer(state, action);
};

export default MembershipPage;
